@import "../../../../assets/styles/common/variables";
@import "../../../../assets/styles/common/type-sizing";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.home-hero-carousel {
  .swiper-slide-visible {
    .home-hero-carousel-image {
      transform: translateX(0) scale(1);
      transition: 750ms;
      transition-delay: 0ms;
    }
  }

  .swiper-slide:not(.swiper-slide-visible) {
    pointer-events: none;

    .home-hero-carousel-text {
      opacity: 0;
      background: $spring-wood;
    }

    .home-hero-carousel-image {
      transform-origin: right center;
      transform: translateX(100%) scale(0.8);
      transition-delay: 750ms;

      @include media-breakpoint-down(sm) {
        transform: translateX(calc(100% - 10px));
      }
    }

    video {
      display: none;
    }
  }

  .swiper-slide-next {
    opacity: 1 !important;
  }

  &-image {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    video, picture {
      position: relative;
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    img {
      width: calc(100% - 25px);
      height: auto;

      @include media-breakpoint-up(md) {
        width: 100%;
        max-height: 95vh;
        object-fit: contain;
        object-position: center right;
      }
    }

    video {
      height: auto;
      width: calc(100% - 25px);
      z-index: 2;
      aspect-ratio: 548 / 730;
      object-fit: cover;
      pointer-events: none;

      &::-webkit-media-controls {
        display: none !important;
      }

      @include media-breakpoint-up(md) {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-text {
    transition: 750ms;
  }

  .swiper-index-current {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-areas: "middle";
    height: 1.2em;
    width: 1.25em;

    .number {
      grid-area: middle;
      transform: translateY(0);
      transition: transform 0.3s ease-in-out;
    }

    #prev-slide {
      transform: translateY(-100%);
    }

    #next-slide {
      transform: translateY(100%);
    }
  }

  .swiper-control {
    position: relative;
    z-index: 2;
  }

  .swiper-pagination-bullet {
    min-width: 36px;

    &-active {
      min-width: 60px;
    }
  }

  .swipe-next {
    position: absolute;
    top: 0;
    height: 100%;
    width: 20%;
    left: calc((100% * 11/12) + 20px);
    text-align: left;
    z-index: 20;
    opacity: 0;
    animation: fadeIn 500ms forwards 1000ms;
  }
}
